import { createWebHistory, createRouter } from 'vue-router';
import pathLoader from '../plugins/loader'
import store from '../store'

const routes = [
    {
        path: '/',
        name: 'homePrivate',
        component: pathLoader(null, 'homePrivate'),
        redirect: 'dashboard',
        children: [
            {
                path: '/dashboard',
                name: 'dashboard',
                component: pathLoader('dashboard'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/produits',
                name: 'produits',
                component: pathLoader('produits'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/commerciaux',
                name: 'commerciaux',
                component: pathLoader('commerciaux'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/medecins',
                name: 'medecins',
                component: pathLoader('medecins'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/personnel',
                name: 'personnel',
                component: pathLoader('personnel'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/fournisseurs',
                name: 'fournisseurs',
                component: pathLoader('fournisseurs'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/marques',
                name: 'marques',
                component: pathLoader('marques'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/assureurs',
                name: 'assureurs',
                component: pathLoader('assureurs'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/points',
                name: 'points',
                component: pathLoader('points'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/caisses',
                name: 'caisses',
                component: pathLoader('caisses'),
                meta: {
                    requiresAuth: true
                }
            },

        ]
    },
    {
        path: '/login',
        name: 'login',
        component: pathLoader('login'),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.token) {
            next()
            return
        }
        next('/login')
    } else {
        next()
    }
})

export default router;
