 import {  createStore  } from 'vuex'
 import createPersistedState from 'vuex-persistedstate'

 const vuex = createStore({
     plugins: [createPersistedState()],
     state: {
         agence: '',
         token: null,
     },
     mutations: {
         save_agence (state, position) {
             state.agence = position
         },
         save_token (state, position) {
             state.token = position
         },
         logout (state, position) {
             state.token = null
             state.agence = ''
         },
     },
     actions: {
         saveAgence ({ commit }, eya) {
             commit('save_agence', eya)
         },
         saveToken ({ commit }, eya) {
             commit('save_token', eya)
         },
         logout ({ commit }, eya) {
             commit('logout', eya)
         },
     },
     getters: {
         trackAgence: state => state.agence,
         token: state => state.token
     }
 })

 export default vuex
